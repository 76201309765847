.App {
  text-align: center;
}

.body {
  text-align: center;
}

.body_content {
  font-family:Arial, Helvetica, sans-serif;
  display: inline-block;
  //width:50%
}
.image_content{
  margin-top: 5em;
  display: inline-block;
}

.logo_header {
  margin-bottom: -3em;
}

.outline{
  border-width: 1;
  border-color: black;
  border-style: solid
}

@media screen and (max-width: 700px) {
  .image_self {
    display: none;
  }
  .entire_body{
    flex-direction: column;
  }
  .body_content {
    width:50%
  }

  .title_size {
    font-size: calc(1.5rem + 1vw);
  }
  .logo_header {
    margin-bottom: -2.5em;
  }
  .in_image {
    display: inline-block;
    width: 60vw;
    margin-left: -4vw;
  }
  .badges_scale_ios{
    margin:15px;
    margin-block:0;
  }
}

@media screen and (min-width: 300px) {
  .badges_container{
    flex-direction: column;
    display: flex;
  }
  .badges_scale_google{
    width:95%
  }
}

@media screen and (min-width: 450px) {
  .badges_container{
    flex-direction: row;
    display: flex;
  }
  .badges_scale_ios{
    margin:9px;
  }
  .badges_scale_google{
    width:auto;
  }
}

@media screen and (max-width: 300px) {
  .in_image {
    display: none;
  }
  .badges_scale_google{
    display:none;
  }
  .badges_scale_ios{
    display:none;
  }
}

@media screen and (min-width: 700px) {
  .image_self {
    display: inline-block;
    max-width: 40vw;
    margin-left: 30px;
    margin-top: 2vw;
  }
  .entire_body{
    display: flex;
    flex-direction: row;
  }
  .body_content {
    width:40%;
    //max-width:40%
  }
  .text_content {
    font-size: calc(1vh + 1vw);
  }
  .bold_content {
    font-size: calc(1.5vh + 1vw);
  }
  .title_size {
    font-size: calc(1.5rem + 1vw);
  }
  .in_image {
    display: none;
  }
}

@media screen and (min-width: 950px) {
  .body_content {
    width:30%;
  }
  .image_self {
    margin-top: 2vw;
  }
}

@media screen and (min-width: 1600px) {
  .body_content {
    width:20%;
  }
  .image_self {
    margin-top: 3vw;
  }
}

@media screen and (min-width: 2000px) {
  .image_self {
    margin-top: 5vw;
  }
}

.footer_content {
  text-align: center;
  padding-top: 1cm;
}